export default {
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      default: config.ecommerce.currency.default,
    },
    showNotifications: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    "payment:created",
    "payment:canceled",
    "payment:complete",
    "payment:completed",
    "payment:failed",
  ],
  data: function () {
    return {
      isLoading: false,
      payment: null,
    };
  },
  computed: {
    safeAmount() {
      return parseInt(this.amount);
    },
  },
  methods: {
    async executePayment(amount, currency) {
      throw new Error("Implement executePayment by a payment component");
    },
    emitCanceledPayment({ context }) {
      this.setIsLoading(false);
      this.$emit("payment:canceled", {
        component: this,
        payment: this.payment,
        context,
      });

      if (this.showNotifications) {
        this.$s.ui.notification("payments.notifications.canceled", "info");
      }
    },

    emitFailedPayment({ context }) {
      this.setIsLoading(false);
      this.$emit("payment:failed", {
        component: this,
        payment: this.payment,
        context,
      });

      if (this.showNotifications) {
        this.$s.ui.notification("payments.notifications.failed", "error");
      }
    },
    emitSuccessfulPayment(arbitraryObject = {}) {
      this.setIsLoading(false);
      if (!arbitraryObject || typeof arbitraryObject !== "object") {
        arbitraryObject = {};
      }

      this.$emit("payment:complete", {
        component: this,
        payment: this.payment,
        ...arbitraryObject,
      });
      this.$emit("payment:completed", {
        component: this,
        payment: this.payment,
        ...arbitraryObject,
      });

      if (this.showNotifications) {
        this.$s.ui.notification("payments.notifications.success", "success");
      }
    },
    setIsLoading(val) {
      this.isLoading = val;
    },
    failCanceled() {
      this.setIsLoading(false);
      this.$emit("payment:failed", {
        component: this,
        payment: this.payment,
        maybePayedOnGateway: false,
        context: { message: "canceled by user" },
      });

      if (this.showNotifications) {
        this.$s.ui.notification("payments.notifications.canceled", "error");
      }
    },
    failAfterPaymentComplete() {
      this.setIsLoading(false);
      this.$emit("payment:failed", {
        component: this,
        payment: this.payment,
        maybePayedOnGateway: true,
        context: { message: "failed after payment" },
      });

      if (this.showNotifications) {
        this.$s.ui.notification("payments.notifications.failThatMightHaveBeenPaid", "error");
      }

    },
  },
};
