<template>
  <div>
    <slot name="before-ui"></slot>

    <form-button
      :icon="offlinePaymentButtonIcon"
      :theme="offlinePaymentButtonTheme"
      :size="offlinePaymentButtonSize"
      class="width-expand"
      :loading="isLoading"
      @click="payOffline(amount, currency)">
      {{ translateUcFirst(offlineButtonText, { amount: readableAmount }) }}
    </form-button>
    <slot name="after-ui"></slot>
  </div>
</template>

<script>
import { ref, reactive, computed, watchEffect } from "vue";
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import paymentMixin from "@/client/extensions/mixins/paymentComponent.js";

export default {
  mixins: [paymentMixin],
  props: {
    offlineButtonText: {
      type: [String, Boolean],
      default: "payments.gateways.offline.buttonText",
    },
    offlinePaymentButtonSize: {
      type: String,
      default: "m",
    },
    offlinePaymentButtonIcon: {
      type: [String, Boolean],
      default: false,
    },
    offlinePaymentButtonTheme: {
      type: String,
      default: "lead",
    },
  },
  // this is inherited, but aparently you cant mixin emits?
  emits: [
    "payment:created",
    "payment:canceled",
    "payment:complete",
    "payment:completed",
    "payment:failed",
  ],
  setup(props) {
    let { asyncOps, asyncOpsReady } = asyncOperations(props);

    return { asyncOps, asyncOpsReady };
  },
  computed: {
    readableAmount() {
      return this.translateNumber(this.amount, "currency", { currency: this.currency });
    },
  },
  methods: {
    async executePayment(amount, currency) {
      return this.payOffline(amount, currency);
    },

    async payOffline(amount, currency) {
      this.setIsLoading(true);
      if (!amount) {
        amount = this.safeAmount;
      }
      if (!currency) {
        currency = this.currency;
      }
      let createResult = await this.createOfflinePayment(amount, currency);
      if (createResult.isError) {
        return this.emitFailedPayment({
          context: { message: "Failed to create offline payment" },
        });

        return false;
      }
      this.payment = createResult.data.item;
      this.$emit("payment:created", { component: this, payment: this.payment });
      let paymentUuid = this.payment.uuid;
      let initResult = await this.initializeOfflinePayment(paymentUuid);

      if (initResult.isError) {
        this.emitFailedPayment({
          context: { message: "Failed to initialize offline payment" },
        });
        return false;
      }
      this.payment = initResult.data.item;
      let finalizeResult = await this.finalizeOfflinePayment(paymentUuid);

      if (finalizeResult.isError) {
        this.emitFailedPayment({
          context: { message: "Failed to finalize offline payment" },
        });
        return false;
      }
      this.payment = finalizeResult.data.item;
      this.emitSuccessfulPayment();
    },
    async createOfflinePayment(amount = 5000, currency = "USD") {
      let url = "payment/";
      let user = this.sg("user.profile");
      let payload = {
        UserId: user.id,
        amount: amount,
        currency: currency,
        note: "test note",
        handler: "offline",
      };

      let result = await this.asyncOps.asyncCall(url, payload, { method: "post" });
      return result;
    },
    async initializeOfflinePayment(uuid) {
      let url = `payment/${uuid}/offline/initialize`;
      let result = await this.asyncOps.asyncCall(url, {}, { method: "patch" });

      return result;
    },
    async finalizeOfflinePayment(uuid) {
      let url = `payment/${uuid}/offline/finalize`;
      let result = await this.asyncOps.asyncCall(url, {}, { method: "patch" });

      return result;
    },
  },
};
</script>

<style scoped lang="scss"></style>
